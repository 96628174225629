<template>
  <v-dialog v-model="display" :width="dialogWidth">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="textFieldProps"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :value="formattedDatetime"
        :class="customClass"
        :outlined="outlined"
        :dense="dense"
        :hide-details="hideDetails"
        :single-line="singleLine"
        class="c-input-small"
        v-on="on"
        readonly
      >
        <template v-slot:progress>
          <slot name="progress">
            <v-progress-linear
              color="primary"
              indeterminate
              absolute
              height="2"
            ></v-progress-linear>
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab key="calendar">
            <slot name="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </slot>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>mdi-clock-time-five</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              full-width
              :locale="$i18n.locale"
              :min="minDate"
              @input="showTimePicker"
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              class="v-time-picker-custom"
              format="24hr"
              v-model="time"
              v-bind="timePickerProps"
              full-width
              :locale="$i18n.locale"
              @click:minute="okHandler"
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <!--<v-card-actions>
                <v-spacer></v-spacer>
                <slot name="actions" :parent="this">
                    <v-btn color="grey lighten-1" text @click.native="clearHandler">{{ clearText }}</v-btn>
                    <v-btn color="green darken-1" text @click="okHandler">{{ okText }}</v-btn>
                </slot>
            </v-card-actions>-->
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00";
const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
const DEFAULT_TIME_FORMAT = "HH:mm";
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = "CLEAR";
const DEFAULT_OK_TEXT = "OK";

export default {
  name: "DateTimePicker",
  model: {
    prop: ["value"],
    event: "change",
  },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
    timeFormat: {
      type: String,
      default: "HH:mm",
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT,
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT,
    },
    textFieldProps: {
      type: Object,
      default: () => {},
    },
    datePickerProps: {
      type: Object,
      default: () => {},
    },
    timePickerProps: {
      type: Object,
      default: () => {},
    },
    value: {
      type: [Date, Number, String, Array, Object],
      default: () => null,
    },
    label: {
      type: String,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    dense: {
      type: Boolean,
      default: () => true,
    },
    hideDetails: {
      type: Boolean,
      default: () => true,
    },
    singleLine: {
      type: Boolean,
      default: () => true,
    },
    customClass: {
      type: String,
      default: () => null,
    },
    minDate: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    dateTimeFormat() {
      return this.dateFormat + " " + this.timeFormat;
    },
    defaultValueTimeFormat() {
      return DEFAULT_DATE_FORMAT + " " + DEFAULT_TIME_FORMAT;
    },
    formattedDatetime() {
      return this.selectedDatetime
        ? moment(this.selectedDatetime).format(this.dateTimeFormat)
        : "";
    },
    selectedDatetime() {
      if (this.date && this.time) {
        return this.date + " " + this.time;
      } else {
        return null;
      }
    },
    dateSelected() {
      return !this.date;
    },
  },
  methods: {
    init() {
      if (!this.value) {
        return;
      }

      let initDateTime;
      if (this.value instanceof Date) {
        initDateTime = this.value;
      } else if (
        typeof this.value === "string" ||
        this.value instanceof String
      ) {
        this.date = moment(this.value).format("YYYY-MM-DD");
        this.time = moment(this.value).format("HH:mm");
        return this.value;
      }

      this.date = moment(initDateTime, DEFAULT_DATE_FORMAT);
      this.time = moment(initDateTime, DEFAULT_TIME_FORMAT);
    },
    okHandler() {
      this.resetPicker();
      this.$emit("change", this.selectedDatetime);
    },
    clearHandler() {
      this.resetPicker();
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit("change", null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    },
  },
};
</script>
